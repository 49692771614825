<div class="top-bar flex-row space-between align-center">
  <div mat-card-avatar class="hobby-avatar">
  </div>
  <div class="hb-logo"></div>
  <h1 i18n class="spacer primary-text text-center">{{ cityName }}</h1>
  <!-- Convert into the class -->
  <mat-icon (click)="picker.open()" color="primary" class="mr-16" *hbFeatureFlag="true">calendar_today</mat-icon>
  <mat-form-field appearance="fill" class="hidden-date-picker">
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" />
      <input matEndDate formControlName="end" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-datepicker-actions>
        <button mat-button matDatepickerCancel class="cancle-button" (click)="applyClick('cancel')" i18n>Cancel</button>
        <button mat-raised-button color="primary" matDatepickerApply (click)="applyClick('apply')" i18n>Apply</button>
      </mat-datepicker-actions>
    </mat-date-range-picker>
  </mat-form-field>
  <mat-icon (click)="filterClick()" color="primary" class="" *hbFeatureFlag="true">filter_alt</mat-icon>
</div>
<mat-tab-group mat-align-tabs="start" (focusChange)="focusChange($event)" color="accent">
  <mat-tab label="All Events" i18n-label *hbFeatureFlag="true">
    @if (!loading) {
      <cdk-virtual-scroll-viewport
      itemSize="130"
      (scrolledIndexChange)="onScrolledIndexChange($event)"
      [minBufferPx]="200"
      [maxBufferPx]="500"
      class="cdk-viewport"
    >
      <div class="event-card-container cursor-pointer" *cdkVirtualFor="let event of events">
        <hb-event-card [event]="event" (onEventClick)="redirectToEventDetails($event)"></hb-event-card>
      </div>
    </cdk-virtual-scroll-viewport>
    }
  </mat-tab>
  <mat-tab label="My Events" i18n-label>
    <ng-container *hbFeatureFlag="true">
      @for (event of myEvents; track event.id) {
        <hb-event-card [event]="event" (onEventClick)="redirectToEventDetails($event)"></hb-event-card>
      }
    </ng-container>
    <ng-container *hbFeatureFlag="false">
      <cdk-virtual-scroll-viewport
        itemSize="130"
        (scrolledIndexChange)="onScrolledIndexChange($event)"
        [minBufferPx]="200"
        [maxBufferPx]="500"
        class="cdk-viewport"
      >
        <div class="event-card-container cursor-pointer" *cdkVirtualFor="let group of uniqueStartDates; let i = index">
          <p class="date-group" [ngClass]="{ fixed: fixedIndex === i }">
            {{ group | weekDay | date: 'EEEE, d MMM' | titlecase }}
          </p>
          @for (event of myEvents; track event.id) {
            @if (event.startDate === group) {
              <hb-event-card [event]="event" (onEventClick)="redirectToEventDetails(event.id)">
                <div class="flex-row align-center">
                  <mat-icon class="event-location-icon" [svgIcon]="event.isIndoor ? 'home' : 'tree'"></mat-icon>
                  @if (event.teamSize) {
                    <p class="team-size-txt">{{ event.teamSize }}v{{ event.teamSize }}</p>
                  }
                </div>
              </hb-event-card>
            }
          }
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </mat-tab>
</mat-tab-group>

<button mat-flat-button class="create-button" (click)="navigateToCreateEvent()">
  <mat-icon class="va-middle">add</mat-icon> <span i18n>Create</span>
</button>

@if (loading) {
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
} 