import { Component, DestroyRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RouteReuseStrategy, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import {
  Event,
  Hobby,
  PaginationInputType
} from 'src/app/services/hobbyts.service';
import { FilterModalComponent } from '../../modals/filter-modal/filter-modal.component';
import { getMatDialogConfig } from 'src/app/utils/material.utils';
import { ReuseStrategy } from 'src/app/services/providers/reuse-strategy.provider';
import { EventCardComponent } from './event-card/event-card.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProfileService } from '../profile/profile.service';
import { FeatureFlagDirective } from 'src/app/shared/feature-flag.directive';
import { CommonModule } from '@angular/common';
import { WeekDayPipe } from '../../pipe/weekday.pipe';
import moment from 'moment';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { EventsService } from './events.service';
import { StorageService } from 'src/app/services/storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadProgressService } from 'src/app/services/load-progress.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface EventData {
  id: string;
  event: string;
  admin: string;
  startTime: string;
  field: string;
  team: string;
}

@Component({
  selector: 'hb-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: ReuseStrategy
    }
  ],
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTabsModule,
    EventCardComponent,
    ScrollingModule,
    FeatureFlagDirective,
    CommonModule,
    WeekDayPipe, 
    MatProgressSpinnerModule
  ]
})
export class EventsComponent implements OnInit {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;

  public fixedIndex = 0;
  public events: Event[] = [];
  public uniqueStartDates: string[];
  public myEvents: Event[] = [];
  public paginationInputType!: PaginationInputType;
  public displayedColumns: string[] = ['event', 'admin', 'startTime', 'field', 'team'];
  public range!: FormGroup;
  public selectedFilter!: PaginationInputType | null;
  public startDate = 0;
  public endDate = 0;
  public selectedTabIndex = 0;
  public cityName = '';
  public loading = false;
  private totalCount = 0;
  private selectedHobbies: Hobby[] = [];
  private LOAD_MORE_THRESHOLD = 50;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private lazyDialog: LazyDialogService,
    private profileService: ProfileService,
    private featureFlagService: FeatureFlagService,
    private eventsService: EventsService,
    private storageService: StorageService,
    private destroyRef: DestroyRef,
    private loadingService: LoadProgressService
  ) {
    this.range = new FormGroup({
      start: new FormControl('', [Validators.required]),
      end: new FormControl('', [Validators.required])
    });

    this.storageService.valueChanges("store-cityName").subscribe((name) => {
      this.cityName = name || "Events";
    })

    this.loading = this.loadingService.loadingState();
  }

  public ngOnInit(): void {
    this.setCityName();
    const selectedHobbyItems = this.profileService.getSelectedHobbyItems();
    if (selectedHobbyItems.length > 0) {
      this.selectedHobbies = selectedHobbyItems;
      this.setCityName();
      this.resetPaginationInputType();
      if (!this.featureFlagService.getFeature()) {
        this.getAllEvents({ paginationInputType: this.paginationInputType });
      } else {
        this.getMyEvents({ paginationInputType: this.paginationInputType });
      }
    }
  }

  public onScrolledIndexChange(event: number): void {
    if (this.featureFlagService.getFeature()) {
      this.fixedIndex = this.uniqueStartDates ? this.uniqueStartDates.findIndex((date: string) => date === this.myEvents[event].startDate) : 0;
    }

    if (this.events.length) {
      const end = this.events.length;
      const remaining = end - event;

      if (remaining < this.LOAD_MORE_THRESHOLD && this.totalCount !== this.events.length) {
        this.paginationInputType.skip++;
        this.getAllEvents({ paginationInputType: this.paginationInputType }, this.events);
      }
    }
  }

  public redirectToEventDetails(eventId: string): void {
    if (this.featureFlagService.getFeature()) {
      this.router.navigate(['edit-event', eventId]);
    } else {
      this.router.navigate(['event-details', eventId]);
    }
  }

  public navigateToCreateEvent(): void {
    this.router.navigate(['add-event']);
  }

  public applyClick(type: string): void {
    if (type === 'apply') {
      this.applyRangeFilter();
    } else {
      this.clearFilters();
    }

    this.loadEventsBaseonCurrentView();
  }

  public filterClick(): void {
    this.openFilterDialog();
  }

  public focusChange(event: any): void {
    this.selectedTabIndex = event.index;
    this.loadEventsBaseonCurrentView();
  }

  private applyRangeFilter(): void {
    if (this.range.valid) {
      const startDate = moment(this.range.value.start).startOf('day').valueOf();
      const endDate = moment(this.range.value.end).endOf('day').valueOf();

      this.startDate = startDate;
      this.endDate = endDate;
      this.paginationInputType.startDate = startDate;
      this.paginationInputType.endDate = endDate;
    }
  }

  private clearFilters(): void {
    this.paginationInputType.startDate = 0;
    this.paginationInputType.endDate = 0;
    this.range.get('start')?.setValue(new Date().setHours(0, 0, 0, 0));
    this.range.get('end')?.setValue(new Date().setHours(0, 0, 0, 0));
    this.startDate = 0;
    this.endDate = 0;
  }

  private loadEventsBaseonCurrentView(): void {
    this.resetPaginationInputType();
    if (this.selectedTabIndex === 0) {
      this.getAllEvents({ paginationInputType: this.paginationInputType });
    } else {
      this.getMyEvents({ paginationInputType: this.paginationInputType });
    }
  }

  private getAllEvents(paginationInputType: any, prevEvents: Event[] = []): void {
    this.eventsService.getAllEvents(paginationInputType).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(({ data }: any) => {
      if (data?.getAllEvents) {
        const { getAllEvents } = data;
        this.events = [...prevEvents, ...getAllEvents.result];
        this.totalCount = getAllEvents.totalCount;
      }
    });
  }

  private getMyEvents(paginationInputType: any): void {
    this.eventsService.getMyEvents(paginationInputType).pipe(
      map(({ data }) => data?.getMyEvents),
      filter((getMyEvents: any) => !!getMyEvents),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((getMyEvents: any) => {
      this.myEvents = getMyEvents.result;
      this.uniqueStartDates = Array.from(new Set(this.myEvents.map((event) => event.startDate)));
    });
  }

  //ToDo: delete after create event will be refacored and dateChange will be deleted
  private fixDate(d: Date): number {
    d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
    return d.setHours(24);
  }

  private async openFilterDialog(): Promise<void> {
    const config = getMatDialogConfig(this.dialog, FilterModalComponent);
    const dialogRef: MatDialogRef<FilterModalComponent, any> =
      await this.lazyDialog.openLazyLoadedDialog<FilterModalComponent>(DialogNames.FILTER, config);

    dialogRef.componentInstance.selectedFilter = this.selectedFilter;
    dialogRef
      .afterClosed()
      .pipe(filter((val) => !!val))
      .subscribe((result: PaginationInputType) => {
        // ToDo: refactor
        if (result && JSON.stringify(this.selectedFilter) !== JSON.stringify(result)) {
          this.selectedFilter = result;
          if (result?.startDate) {
            this.paginationInputType.startDate = this.fixDate(new Date(result.startDate));
          }
          if (result?.startTime) {
            this.paginationInputType.startTime = result.startTime;
          }
          if (result?.isOutdoor) {
            this.paginationInputType.isOutdoor = result.isOutdoor;
          }
          if (result?.isIndoor) {
            this.paginationInputType.isIndoor = result.isIndoor;
          }
          if (result?.openToJoin) {
            this.paginationInputType.openToJoin = result.openToJoin;
          }
          if (result?.followedBy) {
            this.paginationInputType.followedBy = result.followedBy;
          }
          if (result?.hobby) {
            this.paginationInputType.hobby = result.hobby;
          }
          if (result?.teamSize) {
            this.paginationInputType.teamSize = result.teamSize;
          }
          this.events = [];
          this.paginationInputType.skip = 1;
          this.loadEventsBaseonCurrentView();
        }
      });
    dialogRef.componentInstance.resetFormEvent.subscribe(() => {
      this.events = [];
      this.resetPaginationInputType();
      this.loadEventsBaseonCurrentView();
    });
  }

  private resetPaginationInputType(): void {
    this.paginationInputType = {
      city: this.featureFlagService.getFeature() ? "Lviv" : this.storageService.getValue('store-placeId'),
      hobby: this.selectedHobbies.length ? this.selectedHobbies[0].id : null,
      startDate: 0,
      endDate: 0,
      skip: 1,
      limit: 50
    };
  }

  private setCityName(): void {
    this.cityName = this.storageService.getValue('store-cityName') ?? "Events";
  }

  /* openDialog() {
    const config = new MatDialogConfig();
    // config.disableClose = true;
    config.autoFocus = true;
    config.panelClass = 'events-filter-modal  -panel';
    config.backdropClass = 'backdrop-modal-panel';
    const dialogRef = this.dialog.open(FilterModalComponent, config);

    dialogRef.componentInstance.selectedFilter = this.selectedFilter;
    dialogRef.afterClosed().subscribe((result) => {
      if (result && JSON.stringify(this.selectedFilter) != JSON.stringify(result)) {
        this.processResult(result);
      }
      if (this.selectedFilter && !result) {
        this.resetFilters();
      }
    });
  }

  processResult(result: any) {
    this.selectedFilter = result;
    const { startDate, startTime, isOutdoor, isIndoor, followedBy, sportType, teamSize } = result;

    if (startDate) {
      this.paginationInputType.startDate = new Date(startDate).getTime();
    }
    this.paginationInputType.startTime = startTime ?? this.paginationInputType.startTime;
    this.paginationInputType.isOutdoor = isOutdoor ?? this.paginationInputType.isOutdoor;
    this.paginationInputType.isIndoor = isIndoor;
    this.paginationInputType.followedBy = followedBy ?? this.paginationInputType.followedBy;
    this.paginationInputType.sportType = sportType ?? this.paginationInputType.sportType;
    this.paginationInputType.teamSize = teamSize ?? this.paginationInputType.teamSize;

    this.loadEventsBaseonCurrentView();
  }

  resetFilters() {
    this.selectedFilter = null;
    this.paginationInputType = {
      skip: 1,
      limit: 10,
      type: 'public',
      startDate: 0,
      endDate: 0,
    };
    this.loadEventsBaseonCurrentView();
  } */
}
